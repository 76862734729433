import React from 'react'

const PriceFooter = (props) => {
  return (
        <div className="footer">
            <div>
                お見積もり金額
            </div>
            <div>
                {Math.floor(props.price).toLocaleString()}<span className="fs-6 text-muted"> 円（税込）</span>
            </div>
        </div>
  )
}

export default PriceFooter