import React from 'react'

const AreaTitle = (props) => {
  return (
    <div className="text-center">
        <h1 className="fs-4 mt-4 title-color">
          <strong>見積もりシュミレーター</strong>
          <div className="fs-5 my-4 text-muted fw-bold">
            {props.infos.title}
          </div>
        </h1>
    </div>
    )
}

export default AreaTitle