import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import React from 'react'

const Nav = (props) => {
  return (
    <div>

      <nav className="navbar navbar-expand-lg bg-info p-2 bg-opacity-25">
        <div className="container-fluid">
          <Link className="navbar-brand"  to="/"><img src={`${process.env.PUBLIC_URL}/mitumorImg.png`} alt="Logo" width="150" height="auto" className="d-inline-block align-text-top" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/" ><i className="bi bi-chevron-compact-right"></i> 一覧（TOP）</a>
              </li>
              {props.isLogin
              ?
              <li className="nav-item">
                <a className="nav-link" href="/create" ><i className="bi bi-chevron-compact-right"></i> 新規作成</a>
              </li>
              :""
              }
              <li className="nav-item">
                <a className="nav-link" href="/simulator/20231212094622593FszRg" target="_blank"><i className="bi bi-chevron-compact-right"></i> サンプル</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" style={{textDecoration: "none"}} href="https://marbou-work.com/contact/" target="_blank" ><i className="bi bi-chevron-compact-right"></i> お問い合わせ</a>
              </li>
              {props.isLogin
              ?<li className="nav-item">
                <a cl className="nav-link" href="#" onClick={props.handleSignout} style={{textDecoration: "none"}} ><i className="bi bi-chevron-compact-right"></i> ログアウト</a>
              </li>
              :''
              }
            </ul>
          </div>
        </div>
      </nav> 

    </div>
  )
}

export default Nav