import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import './App.css';
import Home from './Home';
import Create from './Create';
import Simulator from './Simulator';

 import Test from './Test';
 import Page404 from './Page404';

function App() {
  //Footer

  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/create" element={<Create />} />
        <Route path="/create/:key" element={<Create />} />

        <Route path="/simulator/:key" element={<Simulator />} />

        <Route path="*" element={<Page404 />} />
{/* 
        <Route path="/test" element={
            <Test />
        } />
        <Route path="/test/:key" element={
            <Test />
        } /> */}

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
