import React from 'react'

const AreaDropBox = (props) => {
  return (
    <div>

        {props.form.type === "ドロップボックス" 
        ? 
        <div className="my-3 row">
            { typeof props.form.fields[0].file === 'object'
            ?  <div className="col-3" id={`dr_ob_content_area_${props.form.id}`}><img className="field-image-preview" id={`image_${props.form.id}`} src={`${URL.createObjectURL(props.form.fields[0].file)}`} /></div> 
            :  props.form.fields[0].file
                ? <div className="col-3" id={`dr_ob_content_area_${props.form.id}`}><img className="field-image-preview" id={`image_${props.form.id}`} src={`${props.form.fields[0].file}`} /></div>
                : ''
            }                         
            <div className="/*col-9*/">
                <div className="me-2 midashi-color ">
                    <h2 className="midashi fs-6"><strong>Q{props.formIndex+1}. {props.form.label}</strong></h2>
                </div>

                <select 
                onChange={(e)=>props.doChangeDropBox(e, props.form.id, props.form.fields)} 
                className="form-select" >
                {props.form.fields.map((field, fieldIndex) => (
                    <option 
                    key={fieldIndex} 
                    value={(field.operation+field.amount) * field.tax}>{field.name}
                    </option>
                ))}              
                </select>
            </div>
        </div>
        :'' 
        }

    </div>
  )
}

export default AreaDropBox