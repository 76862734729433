import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';//tooltip
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <span className="m-3">URLコピーしました</span> 
    </Popover>
  );

const SimulatorUrl = (props) => {
  return (
    <div>
        <div className={`accordion my-3  `} id="msgArea">
        {/* <div className="accordion-item">
            <h2 className="accordion-header ">
            <button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#msg" aria-expanded="false" aria-controls="#msg">
                <OverlayTrigger overlay={<Tooltip id="tooltip-top">クリックで開閉できます</Tooltip>}>
                <i className="m-2 bi bi-question-circle"></i>
                </OverlayTrigger>
                <span className="badge text-bg-secondary "></span>＼作ったシュミレータは下記URLからアクセス！／<span className="biko"></span>
            </button>
            </h2>
            <div id="msg" className="biko pleaseCopy accordion-collapse collapse show" data-bs-parent="#msgArea">
            <div className="accordion-body">
                <div className="">
                    <div htmlFor="editSimulator" className="">＼みんなに使ってもらおう！／</div>
                    <div className="mt-1 mb-1"><strong>{`${process.env.PUBLIC_URL}/simulator/${params.key}`}</strong></div>
                </div>
                <div className="p-3">
                    <a href={`mailto:?subject=お見積もりできます｜${infos.company}&body=${process.env.PUBLIC_URL}/simulator/${params.key}`} className="  text-white mt-2 ms-2  text-wrap"  target="_blank">
                        <img className="snsbtnM" src={`${process.env.PUBLIC_URL}/MAIL.png`} alt="メールで知らせる" />
                    </a>
                    <a href={"https://twitter.com/share?url="+encodeURIComponent(`${process.env.PUBLIC_URL}/simulator/${params.key}`)+"&text="+encodeURIComponent("")} className="  text-white mt-2 ms-2  text-wrap"  target="_blank">
                        <img className="snsbtnX" src={`${process.env.PUBLIC_URL}/Twitter_Icon.png`} alt="twitterで知らせる" />
                    </a>
                    <a href={"https://line.me/R/share?text="+encodeURIComponent("")+"%20"+encodeURIComponent(`${process.env.PUBLIC_URL}/simulator/${params.key}`)} className=" me-2 text-white mt-2 ms-2  text-wrap"  target="_blank">
                        <img className="snsbtnL" src={`${process.env.PUBLIC_URL}/LINEICON.png`} alt="lineで知らせる"  />
                    </a>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-top">押すとURLコピーします  </Tooltip>}>
                    <img className="snsbtnC" src={`${process.env.PUBLIC_URL}/URLCOPY2.png`} onClick={()=>copy(`${process.env.PUBLIC_URL}/simulator/${params.key}`)} alt="URLをコピする"   />
                    </OverlayTrigger>                                
                    <span>{copyMsg}</span>
                </div>
            </div>
            </div>
        </div>   */}

            <div className="alert alert-light" role="alert">
                <div className="">
                    <div className="my-2">
                        <span className=" text-muted fs-6">シュミレーターURL → </span>
                        <a style={{textDecoration: "none"}}  target="_blank" href={`${process.env.PUBLIC_URL}/simulator/${props.params.key}`}><span className="" style={{fontSize:"0.8rem"}}>{`${process.env.REACT_APP_PUBLIC_URL}simulator/${props.params.key}`}</span></a>
                    </div>
                    <a href={`mailto:?subject=お見積もりできます｜${props.infos.company}&body=${process.env.REACT_APP_PUBLIC_URL}simulator/${props.params.key}`} className="  text-white mt-2 ms-2  text-wrap"  target="_blank">
                        <img className="snsbtnM" src={`${process.env.PUBLIC_URL}/MAIL.png`} alt="メールで知らせる" />
                    </a>
                    <a href={"https://twitter.com/share?url="+encodeURIComponent(`${process.env.REACT_APP_PUBLIC_URL}simulator/${props.params.key}`)+"&text="+encodeURIComponent("")} className="  text-white mt-2 ms-2  text-wrap"  target="_blank">
                        <img className="snsbtnX" src={`${process.env.PUBLIC_URL}/Twitter_Icon.png`} alt="twitterで知らせる" />
                    </a>
                    <a href={"https://line.me/R/share?text="+encodeURIComponent("")+"%20"+encodeURIComponent(`${process.env.PUBLIC_URL}/simulator/${props.params.key}`)} className=" me-2 text-white mt-2 ms-2  text-wrap"  target="_blank">
                        <img className="snsbtnL" src={`${process.env.PUBLIC_URL}/LINEICON.png`} alt="lineで知らせる"  />
                    </a>
                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-top">押すと上記URLコピーします  </Tooltip>}>
                    <img className="snsbtnC" src={`${process.env.PUBLIC_URL}/URLCOPY2.png`} onClick={()=>props.copy(`${process.env.REACT_APP_PUBLIC_URL}simulator/${props.params.key}`)} alt="URLをコピする"   />
                    </OverlayTrigger>      */}
                    <OverlayTrigger 
                        trigger="click" 
                        placement="bottom" 
                        overlay={popoverBottom}
                        >
                    <img className="snsbtnC" src={`${process.env.PUBLIC_URL}/URLCOPY2.png`} onClick={()=>props.copy(`${process.env.REACT_APP_PUBLIC_URL}simulator/${props.params.key}`)} alt="URLをコピする"   />
                    </OverlayTrigger>                           
                    {/* <span>{props.copyMsg}</span> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default SimulatorUrl