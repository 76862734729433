import React, { useState , useEffect} from 'react';
import { collection, query, setDoc, updateDoc, getDoc, doc, getDocs, orderBy, where, deleteDoc} from "firebase/firestore";
import { useParams , useNavigate} from "react-router-dom";//urlパラメータ
import { getStorage, ref ,uploadBytes,getDownloadURL, deleteObject, listAll} from "firebase/storage";//storage画像アップロード
import { getAuth, getRedirectResult, GoogleAuthProvider, signOut } from "firebase/auth";

import Scrollspy from 'react-scrollspy'
import { v4 as uuidv4 } from 'uuid';

import Nav from './Nav'
import FooterSec from './FooterSec'
import Preview from './Preview';
import CreateImageUp from './CreateImageUp'//画像アップ機能（保留）
import CreateSideMenu from './CreateSideMenu'//サイドメニュー

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";//firestore
import { firebaseConfig } from  './firebaseConf.js'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';//tooltip
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Manual from './Manual.js';
import SimulatorUrl from './SimulatorUrl.js';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);//firestore
const storage = getStorage();//storage画像アップロード

const selectTypes = {
  'ドロップボックス': 'ドロップボックス',
  'ラジオボタン': 'ラジオボタン',
  'チェックボックス': 'チェックボックス',
};

const selectTaxs = {
  '1.1': '10%',
  '1.08': '8%',
  '1': '税計算しない',
};

const auth = getAuth();

const Test = () => {

  const title = 'サクッと簡単作成！見積もりシュミレーター';
  const site = 'マー坊プロジェクト';

  const [showPreview, setShowPreview] = useState(false);

  //const [actionUrl , setActionUrl] = useState('action-url-none')
  const [copyMsg , setCopyMsg] = useState('')

  const [completeMsg , setCompleteMsg] = useState('')

  const params = useParams();//編集時に使うパラメータ
  const navigate = useNavigate();//リダイレクト

  const [key , setKey] = useState(params.key)//データのキー
  const [formFields, setFormFields] = useState([//見積もり項目
    //{  id: '', label: '', type: 'ドロップボックス', fields: [{ name: '', amount: 0, operation: '+' ,file:'' , tax :10 }] },
    //{  id: 'form2', label: 'Label 1', type: 'ドロップボックス', fields: [{ name: 'Name 1', amount: 0, operation: '+',file:'' , tax :10 }] },
  ]);

  //見積もりに関する備考
  const [infos, setInfo] = useState({
    title:'',
    note:'',
    baseamount:0,
    tax:'10 ',
    company:'',
    mail:'',
    tel:'',
    address:'',
    site:'',
    x:'',
    insta:''
  })

  const [updateCount , setIsUpdate] = useState(0)// 初回レンダリング時とisUpdateが更新されたときのみ実行するためのフラグ

  const [autnName, setAutnName] =useState("")
  const [autnUid, setAutnUid] =useState("")

  useEffect(()  => {  
    


    const fetchData = async () => {
      //console.log("autnName",autnName)

      //認証チェック
      let uid =""
      let displayName =""
      auth.onAuthStateChanged(async (authUser) => {
        if (authUser) {
          //console.log("認証されています")
          setAutnName(authUser.displayName)
          displayName = authUser.displayName
          //console.log(displayName)
          setAutnUid(authUser.uid)
          uid = authUser.uid
          //console.log(uid)

          setKey((prevKey) => (
            prevKey => params.key
          ));
    
          try {
            const docRef = doc(db, "mitumori", key);
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()){  
              setFormFields(docSnap.data().formFields)
              setInfo(docSnap.data().infoFields)
              if(docSnap.data().uuid != uid){//違う人の
                //console.log("違うユーザーの",uid)
                navigate(`/${key}`);//リダイレクト
              }
              //setActionUrl('action-url-block')//編集URLを出す
            }else{//データなし
               navigate(`/${key}`);//リダイレクト
               //console.error('0');
            }
    
          } catch (error) {
            //navigate(`/データエラー`);//リダイレクト
          }
        } else {
          //console.log("認証されていません")
          navigate('/');
        }
      });
    };

    //データ取得
    fetchData();
    window.scrollTo(0,0)

  }, [updateCount]); // 初回レンダリング時とupdateCountが更新されたときのみ実行。

  const onFieldChange = (formId, fieldIndex, key, value) => {
    setFormFields((prevFormFields) => {
      return prevFormFields.map((form) =>
        form.id === formId
          ? {
              ...form,
              [key]: value,
              fields: form.fields.map((field, index) =>
                index === fieldIndex ? { ...field, [key]: value } : field
              ),
            }
          : form
      );
    });
  };

  const onAddField = (formId) => {
    setFormFields((prevFormFields) => {
      return prevFormFields.map((form) =>
        form.id === formId ? { ...form, fields: [...form.fields, { name: '', amount: 0, operation: '+' ,file:'' , tax :1.1}] } : form
      );
    });
  };

  const onRemoveField = (formId, fieldIndex) => {
    let result = window.confirm( 'この項目を削除しますがよろしいですか？');
    if( result ) {
      setFormFields((prevFormFields) => {
        return prevFormFields.map((form) =>
          form.id === formId ? { ...form, fields: form.fields.filter((_, index) => index !== fieldIndex) } : form
        );
      });
    }else{
      return
    }
  };

  const onMoveField = (formId, fieldIndex, direction) => {
    setFormFields((prevFormFields) => {
      return prevFormFields.map((form) => {
        if (form.id === formId) {
          const movedField = form.fields[fieldIndex];
          const remainingFields = form.fields.filter((_, index) => index !== fieldIndex);
          const newIndex = direction === 'up' ? fieldIndex - 1 : fieldIndex + 1;
          const updatedFields = [
            ...remainingFields.slice(0, newIndex),
            movedField,
            ...remainingFields.slice(newIndex),
          ];

          return { ...form, fields: updatedFields };
        } else {
          return form;
        }
      });
    });
  };

  const onAddForm = () => {
    setFormFields((prevFormFields) => {
      const newForm = { id: `form${prevFormFields.length + 1}`, label: '', type: 'ドロップボックス', fields: [{ name: '', amount: 0, operation: '+', file:'' ,file:'' , tax :1.1 }] };
  
      // 新しいフォームを追加した後、フォームのidを順に採番しなおす
      const reorderedForms = [...prevFormFields, newForm].map((form, idx) => ({ ...form, id: `form${idx + 1}` }));
  
      return reorderedForms;
    });
  };

  const onRemoveForm = (formId) => {
    let result = window.confirm( 'このフォームを削除しますがよろしいですか？');

    if( result ) {
      setFormFields((prevFormFields) => {
        const updatedForms = prevFormFields.filter((form) => form.id !== formId);
        // フォームのidを順に採番しなおす
        const reorderedForms = updatedForms.map((form, idx) => ({ ...form, id: `form${idx + 1}` }));
        return reorderedForms;
      });
    }else{
      return
    }

  };

  const onMoveForm = (index, direction) => {
    setFormFields((prevFormFields) => {
      const movedForm = prevFormFields[index];
      const remainingForms = prevFormFields.filter((_, i) => i !== index);
      const newIndex = direction === 'up' ? index - 1 : index + 1;
      const updatedForms = [
        ...remainingForms.slice(0, newIndex),
        { ...movedForm, id: `form${newIndex + 1}` }, // 更新されたidをセット
        ...remainingForms.slice(newIndex),
      ];

      // フォームのidを順に採番しなおす
      const reorderedForms = updatedForms.map((form, idx) => ({ ...form, id: `form${idx + 1}` }));
      //console.log(reorderedForms)

      return reorderedForms;
    });
  };

  //お見積りに関する情報
  const handleNoteInfomations = (e, key) => {
    setInfo((prevInfo) => ({
        ...prevInfo,
        [key]: e.target.value,
      }));

    //[key]: e.target.valueは、指定されたkeyに対応するプロパティの値をe.target.value（通常、inputフィールドの現在の値）に設定しています。
  }

  //更新
  const onSaveToFirestore = async () => {

      let keyId;

      //
      if(formFields.length==0){
        alert("見積もりシュミレータが作られていません。\n「+フォーム追加」を押して見積もりシュミレータをつくろう！")
        return
      }

      if(params.key){//編集
        keyId = params.key

        //既存を削除する（更新すると、フォームの入れ替えしてもidが更新されないから）
        // const delRef = doc(db, 'mitumori', keyId)
        // await deleteDoc(delRef)

      }else{//新規
        //ドキュメントキー生成（日時＋ランダム英文字５桁）
        let alpha_string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

        let ret = getKey()
        keyId = ret[0]+ret[1]+ret[2]+ret[3]+ret[4]+ret[5]+ret[6]

        for ( var i = 0; i < 5; i++ ) {
          keyId += alpha_string.charAt(Math.floor(Math.random() * alpha_string.length));
        }
      }

      // //画像削除
      // const listRef = ref(storage, 'mitumori/' + keyId);
      // listAll(listRef)
      //   .then((res) => {
      //     res.items.forEach((itemRef) => {
      //       //console.log(itemRef)
      //       deleteObject(itemRef).then(() => {//成功
      //           //データ削除成功
      //       }).catch((error) => {
      //           console.log("File download err:"+error.code);
      //       }); 
      //     });
      //   }).catch((error) => {
      //     // Uh-oh, an error occurred!
      //   });

      //選択画像をアップロードする
      for ( let i=0; i<formFields.length; i++) {
        for ( let ii=0; ii<formFields[i]['fields'].length; ii++) {
          //ファイルオブジェクト取得
          let file = formFields[i]['fields'][ii]['file']
          if (typeof file === 'object' && file !== null) {
            //拡張子付きのファイル名を取得する
            let fileName = file.name;
            //ファイル名から拡張子を取得する（splitメソッド）
            let extension = String(fileName).split('.');
            let imageUrl = uuidv4()+"."+extension[1]

            if(extension[1] === undefined){
              formFields[i]['file']=''//なんかここにfileオブジェクト入るから消す
              formFields[i]['fields'][ii]['file']=''
              break;
            }

            let storageRef = ref(storage, "mitumori/"+ keyId + "/" + imageUrl);
            let filePath=storageRef.fullPath
            formFields[i]['fields'][ii]['file']=filePath
            formFields[i]['file']=''//なんかここにfileオブジェクト入るから消す
            //Firebaseにデータを適切に送るために必要なコード
            await uploadBytes(storageRef, file).then((snapshot) => {
              //console.log("blobかfileをアップロード", snapshot);
            });

            //let starsRef = ref(storage, filePath);
            await getDownloadURL(storageRef)
            .then((url) => {
              let s = i*1 + 1*1
              onFieldChange('form'+s, ii, 'file', url)//更新押下後に表示させるために必要
              formFields[i]['fields'][ii]['file']=url//テーブルにフルパスで書き込む
            })
            .catch((error) => {
              console.log("File download err:"+error.code);
            });
          }
        }
      }
      //console.log(formFields)
      let ret = getKey()
      let registered = ret[0]+"年"+ret[1]+"月"+ret[2]+"日 "+ret[3]+":"+ret[4];

      setDoc(doc(db, "mitumori", keyId), {
        uuid: autnUid,
        formFields: formFields,
        infoFields: infos,
        registered: registered

      });

      setIsUpdate((prevUpCnt) => ({
        ...prevUpCnt =>
        prevUpCnt + 1
      }));
      navigate(`/create/${keyId}`);//リダイレクト
      setCompleteMsg("更新完了しました")
  }
  
  //データキー
  const getKey = () => {
    let ret=[]
    let now=new Date();
    let Year=now.getFullYear();
    let Month=(now.getMonth()+1).toString().padStart(2,'0')
    let date=now.getDate().toString().padStart(2,'0')
    let Hour =now.getHours().toString().padStart(2,'0')
    let Min=now.getMinutes().toString().padStart(2,'0')
    let Sec=now.getSeconds().toString().padStart(2,'0')
    let mili=now.getMilliseconds().toString().padStart(3,'0')

    ret =[
      String(Year),
      String(Month),
      String(date),
      String(Hour),
      String(Min),
      String(Sec),
      String(mili)
    ]
    return ret
  }
  
  const copy =(clipboardText)=>{//
    navigator.clipboard.writeText(clipboardText);
    setCopyMsg("URLコピーしました！")
  }

  //シュミレーターを削除
  const deleteData = async (id) => {
    let result = window.confirm( 'このシュミレーターを削除しますがよろしいですか？');
    if( result ) {

        //画像削除
        const listRef = ref(storage, 'mitumori/' + id);
        listAll(listRef)
          .then((res) => {
            res.items.forEach((itemRef) => {
              //console.log(itemRef)
              deleteObject(itemRef).then(() => {//成功
                  //データ削除成功
              }).catch((error) => {
                  console.log("File download err:"+error.code);
              }); 
            });
          }).catch((error) => {
            // Uh-oh, an error occurred!
          });

        //データ削除
        const delRef = doc(db, 'mitumori', id)
        await deleteDoc(delRef)
        navigate(`/create`);//リダイレクト
        setFormFields([]);//フォーム初期化
        setInfo({
          title:'',
          note:'',
          baseamount:0,
          company:'',
          mail:'',
          tel:'',
          address:'',
          site:'',
          x:'',
          insta:''
        });
        setCompleteMsg("シュミレータを削除しました。またのご利用をお待ちしております。")

    }
    else {
        return;
    }
  }

    //ログアウト
    const handleSignout = () => {
      const auth = getAuth();
      signOut(auth);
      setAutnName("ゲスト")
    };

  const onPreview = () => {
    setShowPreview(true);
  };

  return (
    <div>
    <Nav handleSignout={handleSignout} isLogin={true}/>
    <div className="container">
      
      <div className="row">
        <div className="col-12 mb-4 mt-5 ms-3">
          <div className=" text-center" >
            <span className='auth-name text-muted fs-4'>ようこそ！ {autnName} さん</span>
          </div>
        </div>
        
        <div className=" col-md-3 col-12 border-end mt-3 ">

        <div id="list-example" className="sidemenu list-group">
          {formFields.map((form, formIndex) => (
              <a className="mt-3 list-group-item " href={`#sss${formIndex}`} style={{textDecoration: "none"}} >
aa       w       </a>


       ))} 
        </div>
        
        </div>

        <div className=" col-md-9  col-12  mt-1">  
          
 

          <div className="scrollspy-example" data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" tabindex="0">

              <h4 className=" mb-2"  id="sss0">
              aaa<br/> 
              aaa<br/> 
              aaa<br/>   
              aaa<br/> 
              aaa<br/> 
              </h4>

              <h4 className=" mb-2"  id="sss1">
              aaa<br/> 
              aaa<br/>               aaa<br/> 
              aaa<br/>   
              aaa<br/> 
              aaa<br/>   
              aaa<br/> 
              aaa<br/> 
              </h4>

              <h4 className=" mb-2"  id="sss2">
              aaa<br/> 
              aaa<br/> 
              aaa<br/>   
              aaa<br/>               aaa<br/> 
              aaa<br/>   
              aaa<br/> 
              aaa<br/> 
              </h4>

          
              <h4 className=" mb-2"  id="sss3">
              aaa<br/> 
              aaa<br/> 
              aaa<br/>   
              aaa<br/> 
              aaa<br/> 
              </h4>
          </div>

          <div className="btn-group my-3" role="group" aria-label="Basic mixed styles example">
            <button className="btn btn-primary" onClick={onAddForm}><i className="bi bi-plus"></i> フォーム追加</button>
            <button className="btn btn-info text-white" onClick={onPreview} data-bs-toggle="modal" data-bs-target="#previewModal" ><i className="bi bi-eye"></i> プレビュー</button>
            <button className="btn btn-warning text-white" onClick={onSaveToFirestore}><i className="bi bi-arrow-clockwise"></i> 更新</button>
            {params.key 
            ? <button className={`btn btn-danger text-white `} onClick={()=>deleteData(params.key)}><i className="bi bi-trash3"></i> シュミレータを削除する</button> 
            : ''
            }
          </div>

          {/* プレビュー */}
          <div className="modal fade" id="previewModal"  aria-labelledby="previewModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="previewModalLabel">プレビュー</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {showPreview && <Preview formFields={formFields} infos={infos}  />}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary modal-btn" data-bs-dismiss="modal">閉じる</button>
                  {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                </div>
              </div>
            </div>
          </div>


          <div className="accordion my-2" id="accordionInfo">
            <div className="accordion-item">
                <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInfo" aria-expanded="false" aria-controls="collapseInfo">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-top">クリックで開閉します</Tooltip>}>
                    <i className="m-2 bi bi-question-circle"></i>
                    </OverlayTrigger>
                    ご連絡先情報・備考など
                </button>
                </h2>
                <div id="collapseInfo" className="accordion-collapse collapse" data-bs-parent="#accordionInfo">
                    <div className="accordion-body">

                        {/* <div className="m-2">
                            <label htmlFor="baseamount" className="form-label">初期お見積り料金</label>
                            <input type="number" value={infos.baseamount} min={0}  onChange={(e)=>handleNoteInfomations(e,"baseamount")} className="form-control" id="baseamount" name="baseamount" placeholder="例）10000" />
                        </div> */}

                        <div className="m-2">
                            <label htmlFor="note" className="form-label">お見積りに関する備考</label>
                            <textarea value={infos.note} onChange={(e)=>handleNoteInfomations(e,"note")} className="form-control" id="note" name="note" rows="3" placeholder="例）本シミュレーションは実際のご請求額とは異なります。"></textarea>
                        </div>

                        <div className="m-2">
                            <label htmlFor="company" className="form-label">企業名／店名など事業者名</label>
                            <input type="text" value={infos.company} onChange={(e)=>handleNoteInfomations(e,"company")} className="form-control" id="company" name="company" />
                        </div>

                        <div className="m-2">
                            <label htmlFor="mail" className="form-label">メールアドレス</label>
                            <input type="email" value={infos.mail}  onChange={(e)=>handleNoteInfomations(e,"mail")} className="form-control" id="mail" name="mail" placeholder="例）name@example.com" />
                            <div id="" className="form-text">お問合せ先のメールアドレス</div>
                        </div>

                        <div className="m-2">
                            <label htmlFor="tel" className="form-label">電話番号</label>
                            <input type="text" value={infos.tel}  onChange={(e)=>handleNoteInfomations(e,"tel")} className="form-control" id="name" name="tel" placeholder="例）090-8888-9999"  />
                            <div id="" className="form-text">お問合せ先の電話番号</div>
                        </div>

                        {/* <div className="m-2">
                            <label htmlFor="address" className="form-label">住所</label>
                            <input type="text" value={infos.address}  onChange={(e)=>handleNoteInfomations(e,"address")} className="form-control" id="address" name="address" placeholder=""  />
                        </div> */}

                        <div className="m-2">
                            <label htmlFor="site" className="form-label">ホームページ</label>
                            <input type="text" value={infos.site} onChange={(e)=>handleNoteInfomations(e,"site")} className="form-control" id="site" name="site" placeholder="例）https://example.com"  />
                        </div>

                        <div className="m-2">
                            <label htmlFor="x" className="form-label">SNS（X）</label>
                            <div className="input-group">
                                <span className="input-group-text" id="inputGroupPrepend">@</span>
                                <input type="text" value={infos.x} onChange={(e)=>handleNoteInfomations(e,"x")} className="form-control" id="x" name="x" placeholder="例）example"  />
                            </div>
                            <div id="" className="form-text">エックス（X）のアカウント</div>
                        </div>

                        <div className="m-2">
                            <label htmlFor="insta" className="form-label">SNS（Instagram）</label>
                            <div className="input-group">
                                <span className="input-group-text" id="inputGroupPrepend">@</span>
                                <input type="text" value={infos.insta} onChange={(e)=>handleNoteInfomations(e,"insta")} className="form-control" id="insta" name="insta" placeholder="例）example"  />
                            </div>
                            <div id="" className="form-text">Instagramのアカウント</div>
                        </div>
                    </div>
                </div>
            </div>
          </div>

        </div>
      
      </div>{/* container */}
      
    </div>
    <FooterSec title={title} site={site}/>

  </div>
  );
}
export default Test