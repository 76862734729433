import React from 'react'

const Privacy = () => {
  return (

    <div className="modal fade" id="policyModal" tabindex="-1" aria-labelledby="policyModallLabel" aria-hidden="true" >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="policyModallLabel">プライバシーポリシー</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body text-muted p-4 fs-6" style={{textAlign:"left"}}>
          <div className="mt-2 fw-bold">
            1.個人情報の取得
          </div>
          <div className="mt-2">
            当サイトでは、Googleが提供している分析ツール「Google Analytics」を利用して、訪問者の行動を分析しています。 <br/>
            （Google Analytics のデータのプライバシーとセキュリティについては<a href="https://support.google.com/analytics/answer/6004245?hl=ja">こちら</a>）
            このGoogle Analyticsはトラフィックデータの収集のためにCookieを使用しています。 このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
            この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
          </div>
          <div className="mt-2 fw-bold">
            2.Cookieの使用について
          </div>
          <div className="mt-2">
            当サイトでは、一部のコンテンツについてCookieを利用しています。
            Cookieとは、サイトにアクセスした際にブラウザに保存される情報で、名前やメールアドレスなどの個人情報は含みません。
            当サイトのアクセス解析や、アンケートの正常なご利用にCookieを使用しています。
            ブラウザの設定により、Cookieを使用しないようにすることもできます。
          </div>
          <div className="mt-2 fw-bold">
            3.個人情報の利用
          </div>
          <div className="mt-2">
            当サイトでは、メールでのお問い合わせの際に、名前（ニックネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。
            これらの個人情報は、質問に対する回答や必要な情報を電子メール等でご連絡する場合に利用させていただくものです。個人情報をご提供いただく際の目的以外では利用いたしません。
          </div>
          <div className="mt-2 fw-bold">
            4.個人情報の保管
          </div>
          <div className="mt-2">
            お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持等の必要な措置を講じ、 安全対策を実施し個人情報の厳重な管理を行ないます。
          </div>
          <div className="mt-2 fw-bold">
            5.第三者への開示
          </div>
          <div className="mt-2">
            次の場合を除いて、お客様からいただいた個人情報を、第三者に開示することはありません。<br/>
            ・提供者の同意がある場合<br/>
            ・法令に基づく場合<br/>
            ・不正行為やその他の違法行為を防ぐために個人情報の開示が必要となった場合<br/>
          </div>
          <div className="mt-2 fw-bold">
            6.個人情報のお客様への開示
          </div>
          <div className="mt-2">
            お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、 対応させていただきます。
          </div>
          <div className="mt-2 fw-bold">
            7.免責事項
          </div>
          <div className="mt-2">
            当サイトに掲載されている情報の正確さには万全を期していますが、利用者が当Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。<br/>
            当サイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
          </div>
          <div className="mt-2 fw-bold">
            8.著作権・肖像権について
          </div>
          <div className="mt-2">
            当サイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。<br/>
            ただし、当サイト記事からの引用に関しましては「引用元の明示」によって無償で引用頂けますが、全文転載はお断りいたしております。<br/>
            引用許可範囲についても、 事前予告なくこれを変更する事があります。<br/>
            また、当サイトのRSSを利用し、コンテンツをそのまま盗用することも禁止しています。
          </div>
          <div className="mt-2 fw-bold">
            9.プライバシーポリシーの変更について
          </div>
          <div className="mt-2">
            当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。<br/>
            修正された最新のプライバシーポリシーは常に本ページにて開示されます。
          </div>

        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">閉じる</button>
        </div>
      </div>
    </div>
    </div>
        
  )
}

export default Privacy