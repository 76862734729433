import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';//tooltip
import Tooltip from 'react-bootstrap/Tooltip';

const CreateSideMenu = (props) => {
  return (

<div className="sidemenu mb-3">
    <span className="text-muted fw-bold">該当のフォームへ飛べる目次</span> 
    <div id="list-example" className="list-group">
    {props.formFields.map((form, formIndex) => (
        <a className="mt-3 /*list-group-item*/ " key={form.id} href={`#accordionNote${formIndex}`} style={{textDecoration: "none"}} >
           <i className="bi bi-chevron-right text-muted"></i> <span className="badge text-bg-secondary "> フォーム.{formIndex+1}</span><span className="text-muted ">｜{form.label}</span>
        </a>
    ))}
    </div>

    <div className="my-3">
      <a href={`#accordionInfo`} style={{textDecoration: "none"}}>
        <i className="bi bi-chevron-right text-muted"></i> 
        <span  className="badge text-bg-secondary "></span><span className="text-muted "> ご連絡先情報・備考など</span>
      </a>
    </div>

    <hr/>
    <div className="/*btn-group-vertical*/ d-grid gap-2 my-3" role="group" aria-label="Basic mixed styles example">
      <button className="btn btn-primary" onClick={props.onAddForm}><i className="bi bi-plus"></i> フォーム追加</button>
      <button className="btn btn-info text-white" onClick={props.onPreview} data-bs-toggle="modal" data-bs-target="#previewModal" ><i className="bi bi-eye"></i> プレビュー</button>
      <button className="btn btn-warning text-white" onClick={props.onSaveToFirestore}><i className="bi bi-arrow-clockwise"></i> 更新する</button>
      {props.params.key 
      ? <button className={`btn btn-danger text-white `} onClick={()=>props.deleteData(props.params.key)}><i className="bi bi-trash3"></i> シュミレータを削除する</button> 
      : ''
      }
    </div>
    <hr/>

    {/* <div className="" role="group" >
          <div className="m-3">
            <a className="text-muted" style={{textDecoration: "none"}} href="/" ><i className="bi bi-chevron-compact-right"></i> （TOP）一覧へ戻る</a>
          </div>
          <div className="m-3">
            <a className="text-muted" href="/create" style={{textDecoration: "none"}} ><i className="bi bi-chevron-compact-right"></i> 新規作成する</a>
          </div>
          <div className="m-3">
            <a className="text-muted" href="/simulator/20231210213103546Tdskv" style={{textDecoration: "none"}} target="_blank"><i className="bi bi-chevron-compact-right"></i> サンプルを見る</a>
          </div>
          <div className="m-3">
            <a className="text-muted" style={{textDecoration: "none"}} href="https://marbou-work.com/contact/" target="_blank" ><i className="bi bi-chevron-compact-right"></i> お問い合わせ</a>
          </div>
          <div className="m-3">
            <a className="text-muted" href="#" onClick={props.handleSignout} style={{textDecoration: "none"}} ><i className="bi bi-chevron-compact-right"></i> ログアウト</a>
          </div>
    </div> */}

    {props.params.key 
      ? ""
      // <div className={`alert alert-light mt-3 biko pleaseCopy text-center `} role="alert" >
      //   <div></div>
      //   <div className="">
      //       <div htmlFor="editSimulator" className="">＼作ったシュミレータを公開！／</div>
      //       <div className="mt-1 mb-1"><strong>{`${process.env.PUBLIC_URL}/simulator/${params.key}`}</strong></div>
      //   </div>
      //   <div className="p-3">
      //       <a href={`mailto:?subject=お見積もりできます｜${props.infos.company}&body=${process.env.PUBLIC_URL}/simulator/${props.params.key}`} className="  text-white mt-2 ms-2  text-wrap"  target="_blank">
      //           <img className="snsbtnM-side" src={`${process.env.PUBLIC_URL}/MAIL.png`} alt="メールで知らせる" />
      //       </a>
      //       <a href={"https://twitter.com/share?url="+encodeURIComponent(`${process.env.PUBLIC_URL}/simulator/${props.params.key}`)+"&text="+encodeURIComponent("")} className="  text-white mt-2 ms-2  text-wrap"  target="_blank">
      //           <img className="snsbtnX-side" src={`${process.env.PUBLIC_URL}/Twitter_Icon.png`} alt="twitterで知らせる" />
      //       </a>
      //       <a href={"https://line.me/R/share?text="+encodeURIComponent("")+"%20"+encodeURIComponent(`${process.env.PUBLIC_URL}/simulator/${props.params.key}`)} className=" me-2 text-white mt-2 ms-2  text-wrap"  target="_blank">
      //           <img className="snsbtnL-side" src={`${process.env.PUBLIC_URL}/LINEICON.png`} alt="lineで知らせる"  />
      //       </a>
      //       <OverlayTrigger overlay={<Tooltip id="tooltip-top">押すとURLコピーします  </Tooltip>}>
      //       <img className="snsbtnC-side" src={`${process.env.PUBLIC_URL}/URLCOPY2.png`} onClick={()=>props.copy(`${process.env.PUBLIC_URL}/simulator/${props.params.key}`)} alt="URLをコピする"   />
      //       </OverlayTrigger>
      //       <span>{props.copyMsg}</span>
      //   </div>
      //   <div className="">
      //       <div  className="mb-1">＼編集用URLはこちら！／</div>
      //       <div className="mt-1 mb-1"><strong>{`${process.env.PUBLIC_URL}/create/${params.key}`}</strong></div>
      //   </div>
      // </div>
      : ''
      }



  </div>

    )
}

export default CreateSideMenu