import React from 'react'
import { useEffect, useState } from "react";
import { useParams , useNavigate} from "react-router-dom";//urlパラメータ
import { collection, query, where, getDocs, getFirestore, orderBy } from "firebase/firestore";

import { v4 as uuidv4 } from 'uuid';

import Nav from './Nav'
import FooterSec from './FooterSec'

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithRedirect, GoogleAuthProvider, signInWithPopup, getRedirectResult ,signOut, setPersistence ,browserSessionPersistence} from 'firebase/auth';
import { firebaseConfig } from  './firebaseConf.js'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);//firestore

const provider = new GoogleAuthProvider();
const auth = getAuth();

const Home = () => {
    const title = 'サクッと簡単作成！見積もりシュミレーター';
    const site = 'マー坊プロジェクト';

    const navigate = useNavigate();//リダイレクト
    const [error, setError] = useState('');
  
    const [isLogin, setIsLogin] = useState(false);
    const [isAuthProcess, setIsAuthProcess] = useState("");

    const [autnName, setAutnName] =useState("ゲスト")

    const [formList, setFormLists] = useState([])

    //ログイン
    const handleLogin = async (event) => {
      //alert("click!")
      signInWithPopup(auth, provider)
      //signInWithRedirect(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        //console.log(user)
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.log(error)
      });
    }

    //ログアウト
    const handleSignout = () => {
      const auth = getAuth();
      signOut(auth);
      setAutnName("ゲスト")
      setIsLogin(false)
      setIsAuthProcess("認証してください")
      navigate('/create');//ここHomeに戻るとレンダリングされないので
    };

    useEffect( () => {
      document.title = title +"｜"+ site;
      //document.body.classList.add("bg-info", "p-2", "bg-opacity-25");

      //認証チェック
      auth.onAuthStateChanged(async (authUser) => {
        if (authUser) {
          //console.log("認証されています")
          //console.log(authUser)
          setAutnName(authUser.displayName)
          setIsLogin(true)

          const elm = document.getElementById('no-auth');

          if (elm !== null) {
            elm.classList.remove('login-btn-on');
            elm.classList.add('login-btn-off');
          }

          //このユーザーのデータを取得する
          const q = query(collection(db, "mitumori"), where("uuid" , "==" , authUser.uid) , orderBy('registered', 'desc'));
          const querySnapshot = await getDocs(q);
          setFormLists(prevFormList => {
            return [
              ...prevFormList,
              ...querySnapshot.docs.map(doc => ({
                id: doc.id,
                title: doc.data().infoFields.title,
                date: doc.data().registered
              }))
            ];
          });
            
        } else {
          //console.log("認証されていません")

          //認証中ですお待ちください→消す
          const elm_a = document.getElementById('antoritying');
          if (elm_a !== null) {
            elm_a.classList.remove('login-btn-on');
            elm_a.classList.add('login-btn-off');
          }

          //認証する
          // getRedirectResult(auth)
          // .then((result) => {
          //     const credential = GoogleAuthProvider.credentialFromResult(result);
          //     const token = credential.accessToken;
          //     const user = result.user;
          //     //console.log(user.uid)
          //     //console.log(user.email)
          //     setAutnName(user.displayName)
          //     setIsLogin(true)

          //     //このユーザーのデータを取得する
          //     const q = query(collection(db, "mitumori"), where("uuid" , "==" , authUser.uid) , orderBy('registered', 'desc'));
          //     const querySnapshot = getDocs(q);
          //     setFormLists(prevFormList => {
          //       return [
          //         ...prevFormList,
          //         ...querySnapshot.docs.map(doc => ({
          //           id: doc.id,
          //           title: doc.data().infoFields.title,
          //           date: doc.data().registered
          //         }))
          //       ];
          //     });

          // }).catch((error) => {
          //     const errorCode = error.code;
          //     const errorMessage = error.message;
          //     const credential = GoogleAuthProvider.credentialFromError(error);
          //     //console.log("err:"+errorCode)
          // });

          const elm = document.getElementById('no-auth');
          if (elm !== null) {
            elm.classList.remove('login-btn-off');
            elm.classList.add('login-btn-on');
          }
        }
      });

    },[])

    //認証中ですお待ちください→消す
    const elm_a = document.getElementById('antoritying');
    if (elm_a !== null) {
      elm_a.classList.remove('login-btn-on');
      elm_a.classList.add('login-btn-off');
    }

   return (
  <div>
    <Nav handleSignout={handleSignout} isLogin={isLogin}/>
    <div className="container">
      <div className="row">

        {/* <div className=" col-md-3 col-12 border-end mt-3 ">
          {isLogin
          ?
          <div className="m-3">
            <a className="text-muted" href="/create" style={{textDecoration: "none"}} ><i className="bi bi-gear"></i> 新規作成する</a>
          </div>
          :''
          }          
          <div className="m-3">
            <a className="text-muted" href="/sample1" style={{textDecoration: "none"}} target="_blank"><i className="bi bi-file-text" ></i> サンプルを見る</a>
          </div>
          <div className="m-3">
            <a className="text-muted" style={{textDecoration: "none"}} href="https://marbou-work.com/contact/" target="_blank" ><i className="bi bi-send"></i> お問い合わせ</a>
          </div>
          {isLogin
          ?
          <div className="m-3">
            <a className="text-muted" href="#" onClick={handleSignout} style={{textDecoration: "none"}} > ログアウト</a>
          </div>
          :''
          }
        </div> */}

        <div className="m-4 text-center " id="antoritying">
          <div className="my-2">認証中です。しばらくお待ちください...</div>   
          <div className="text-center">
            <div className="spinner-border text-primary text-opacity-75" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      
        {isLogin
        ?  
        <div className="  col-10 offset-1  mt-3">  
          <div className="my-5" >
               <span className='auth-name text-muted fs-4'>ようこそ！ {autnName} さん</span>
          </div>
          {formList.length > 0
          ?
          <div className="my-3" >
               <span className='text-muted fs-6'>作成したシュミレータ</span>
          </div>
          :
          <div className="my-3" >
            <span className='text-muted fs-6'>下記「新規作成」から、オリジナルのお見積りシュミレータを作ってみよう！</span>
          </div>
          }

          <div className="my-3">
            <table className="table table-striped table-hover">
              {/* <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">title</th>
                  <th scope="col">registed</th>
                  <th scope="col">edit</th>
                </tr>
              </thead> */}
              <tbody>
          {formList
            ?
            formList.map((form, formIndex) => (
              <tr key={formIndex}>
                <th scope="row">{formIndex+1}</th>
                <td><span className="text-muted">{form.date}</span></td>
                <td><a href={`/create/${form.id}`} className="text-muted " style={{textDecoration: "none"}} >{form.title}</a></td>
                <td>
                  <a href={`/create/${form.id}`} className=" btn btn-primary btn-sm" style={{textDecoration: "none"}} >編集</a></td>
              </tr>
            ))
            :''
          }
              </tbody>
            </table>
          </div>

          <div className="m-2">
            <a className="btn btn-warning text-white" href="/create" style={{textDecoration: "none"}} ><i className="bi bi-chevron-compact-right"></i> 新規作成する</a>
          </div>

          <div className="my-5 alert alert-light">
            <div className="m-3">
              <a className="text-muted" href="/create" style={{textDecoration: "none"}} ><i className="bi bi-chevron-compact-right"></i> 新規作成する</a>
            </div>
            <div className="m-3">
              <a className="text-muted" href="/simulator/20231210213103546Tdskv" style={{textDecoration: "none"}} target="_blank"><i className="bi bi-chevron-compact-right"></i> サンプルを見る</a>
            </div>
            <div className="m-3">
              <a className="text-muted" style={{textDecoration: "none"}} href="https://marbou-work.com/contact/" target="_blank" ><i className="bi bi-chevron-compact-right"></i> お問い合わせ</a>
            </div>
            <div className="m-3">
              <a className="text-muted" style={{textDecoration: "none"}} href="#" onClick={handleSignout} ><i className="bi bi-chevron-compact-right"></i> ログアウト</a>
            </div>
          </div>

        </div>
        :
        <div className="col-10 offset-1  mt-3 login-btn-off text-center" id="no-auth" >  
          <h1 className="fs-4 fw-bold my-4 top-title  ">サクッと簡単作成！見積もりシュミレーター</h1>
          <div className="my-5 text-muted">
            <div className="lh-lg">
            「サクッと簡単作成！お見積りシュミレーター」とは、<span className="home-msg"><strong>オリジナルのお見積もりサービス</strong></span>が作れるプラットフォームのこと。
            </div>
            <div className="my-3 lh-lg text-muted">
              当サービスでは、オリジナルの見積もりシュミレーターが<strong>カンタン</strong>にかつ<strong>無料</strong>で作れます。
              <div className="my-3">
                <a className="btn btn-outline-info " href="/simulator/20231212094622593FszRg" target="_blank"><i className="bi bi-chevron-compact-right"></i> サンプルを見てみる</a>
              </div>
              あなたのユーザーやお客さんなどが、作ったオリジナルの見積もりシュミレーターで概算を把握できれば、成約にも繋げられそうですよね。
            </div>
            <div className="my-3 text-muted">
              ご利用には面倒なアカウント作成もなく、お持ちのGoogleアカウントさえあれば大丈夫！
            </div>
          </div>
          <div className="text-center">
            <div className="my-3">
              ＼以下より早速はじめよう！／
            </div>
            <img onClick={handleLogin} src={`${process.env.PUBLIC_URL}/web_light_sq_SI@3x.png`} alt="signin" className="signin d-inline-block align-text-top mb-3" style={{cursor: "pointer"}}/>
          </div>
        </div>

        }
      </div>
    </div>
    <FooterSec title={title} site={site}/>
  </div>
  )
}

export default Home