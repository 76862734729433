import React from 'react'
import { Link } from "react-router-dom"

import Nav from './Nav'
import FooterSec from './FooterSec'

const Page404 = () => {
  const title = 'サクッと簡単作成！見積もりシュミレーター';
  const site = 'マー坊プロジェクト';

  return (
    <div>
      <Nav />
      <div className="container">
        <div className="row text-center m-4">
          <h1><span className="fs-4">404 NOT FOUND</span></h1>
          <p>お探しのページが見つかりませんでした。</p>
          <Link to="/">Topに戻る</Link>
        </div>
      </div>
      <FooterSec title={title} site={site}/>
    </div>
    )
}

export default Page404