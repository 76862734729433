import React from 'react'

const PriceBody = (props) => {
  return (
    <div className="text-center mt-4">
    <h2 className="fs-4 mt-1  text-muted ">
      <strong>お見積もり金額</strong>
      <div className="mt-2">
        <span className="fs-2 text-muted">{Math.floor(props.price).toLocaleString()}</span>円
        <span className="fs-6 text-muted">（税込）</span>
      </div>
    </h2>

  </div>

    )
}

export default PriceBody