import React from 'react'

const Contact = (props) => {
  return (
    <div className="text-center mt-4">
        {props.infos.mail || props.infos.mail
         ? <div className="mb-2 text-muted">＼お問い合わせはこちら／</div>
         : ''
        }
        {/* <button type="button" className="text-white btn btn-info">お問合せする</button> */}
        {props.infos.mail
         ? <a href={`mailto:${props.infos.mail}`}><button type="button" className="me-2 btn text-white btn-info"><i className="bi bi-send-fill"></i> メール</button></a>
         : ''
        }
        {props.infos.mail
         ? <a href={`tel:${props.infos.tel}`}><button type="button" className="ms-2 btn text-white btn-warning"><i className="bi bi-telephone-fill"></i> {props.infos.tel}</button></a>    
         : ''
        }
    </div>
    )
}

export default Contact