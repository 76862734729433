import React from 'react'
import { useState, useEffect} from 'react';
import { useParams , useNavigate} from "react-router-dom";//urlパラメータ

import AreaTitle from "./AreaTitle";
import AreaDropBox from "./AreaDropBox";
import AreaRadio from "./AreaRadio";
import AreaCheckBox from "./AreaCheckBox";
import PriceBody from "./PriceBody";
import Contact from "./Contact";
import Infomation from "./Infomation";
import PriceFooter from "./PriceFooter";
import FooterSec from "./FooterSec";

const Preview = ({ formFields, infos }) => {
    // プレビュー用のフォーム構築ロジック
  
    const params = useParams();//編集時に使うパラメータ
  
    const [price, setPrice] = useState(0);//見積もり料金
  
    const [dropBoxPlice, setDropBoxPlice] = useState({})
    const [radioPlice, setRadioPrice] = useState({})
    const [checkBoxePlice, setCheckBoxPrice] = useState({})  

    useEffect(() => {
        const fetchData = async () => {              
            //初期選択設定（チェックボックスとドロップボックスは先頭のものが初期選択）
            for ( let i=0; i<formFields.length; i++) {
                if(formFields[i]['type'] == "ドロップボックス"){
                    let s = Number(i) + Number(1)
                    setDropBoxPlice((prevItemsDropBoxe) => {
                    return { ...prevItemsDropBoxe, ["form"+s]: formFields[i]['fields'][0]['operation'] 
                    + formFields[i]['fields'][0]['amount']*formFields[i]['fields'][0]['tax'] }
                    });
                }
                if(formFields[i]['type'] == "ラジオボタン"){
                    let s = Number(i) + Number(1)
                    setRadioPrice((prevItemsRadio) => {
                    return { ...prevItemsRadio, ["form"+s]: formFields[i]['fields'][0]['operation']
                    + formFields[i]['fields'][0]['amount']*formFields[i]['fields'][0]['tax'] }
                    });
                }
            }
        };
    
        let sumDropBoxPlice = 0
        Object.values(dropBoxPlice).map((item) => {
          sumDropBoxPlice = parseFloat(sumDropBoxPlice) + parseFloat(item)
        })
    
        let sumRadioPlice = 0
        //console.log(radioPlice)
        Object.values(radioPlice).map((item) => {
          sumRadioPlice = parseFloat(sumRadioPlice) + parseFloat(item)
          //console.log(item)
        })
    
        let sumCheckBoxPlice = 0
        Object.values(checkBoxePlice).map((item) => {
          sumCheckBoxPlice = parseFloat(sumCheckBoxPlice) + parseFloat(item)
          //console.log(item)
        })
        //console.log(Object.keys(checkBoxePlice).length, Object.keys(radioPlice).length, Object.keys(dropBoxPlice).length)
        //console.log(sumDropBoxPlice, sumRadioPlice, sumCheckBoxPlice)
        setPrice((prevPlice) =>   parseFloat(sumDropBoxPlice) 
          + parseFloat(sumRadioPlice) 
          + parseFloat(sumCheckBoxPlice) )
    
        //データ取得（初回だけ）
        if(Object.keys(dropBoxPlice).length === 0 &&
           Object.keys(radioPlice).length === 0 &&
           Object.keys(checkBoxePlice).length === 0 
        ){
          fetchData();
        }
            
      },[dropBoxPlice, radioPlice, checkBoxePlice]); //


  const doChangeDropBox =  (e, formId, fields) =>  {
    //setPrice((prevPlice) => 0)//ゼロクリア
    const inputPlice = e.target.value;
    setDropBoxPlice((prevItemsDropBoxe) => {
      return { ...prevItemsDropBoxe, [formId]: inputPlice }
    });

    let file = fields[e.target.selectedIndex].file
    if(file){
        if(typeof file === 'object'){
            //console.log(file)
            // console.log("オブジェクトdす")
            file = URL.createObjectURL(file)
        }
        let img = document.getElementById(`image_${formId}`);
        img.remove();

        // img要素を作成
        let img_element = document.createElement('img');
        img_element.src = file; // 画像パス
        img_element.className = "field-image-preview"; // 
        img_element.id = `image_${formId}`; //
        // 指定した要素にimg要素を挿入
        let content_area = document.getElementById(`dr_ob_content_area_${formId}`);
        content_area.appendChild(img_element);
        //img.setAttribute('src', "");
        //img.setAttribute('src', file);
    }

  }

  const doChangeRadio =  (e, formId) =>  {
    //setPrice((prevPlice) => 0)//ゼロクリア
    const inputPlice = e.target.value;
    //console.log(inputPlice)
    setRadioPrice((prevItemsRadio) => {
      return { ...prevItemsRadio, [formId]: inputPlice }
    });

  }
  
  const doChangeCheckBox =  (e, formId, fieldIndex) =>  {
    const inputPlice = e.target.value;
    const isChecked = e.target.checked;
    //console.log(inputPlice)
    setCheckBoxPrice((prevItemsCheckBox) => {
      if (isChecked) {
        return { ...prevItemsCheckBox, [formId+fieldIndex]: inputPlice }
      } else {
        // チェックが外れた場合、指定されたフォームIDとフィールドインデックスに対応する要素を削除
        return Object.fromEntries(
          Object.entries(prevItemsCheckBox).filter(
            ([key]) => key !== formId + fieldIndex
          )
        );
      }
    });


  }

    return (
        <div className="">
          <div className="container bg-white">
            <div className="p-2 col-md-10 offset-md-1">

              <AreaTitle infos={infos} />

              <hr className="hr-color"/>

              {formFields.map((form, formIndex) => (
                <div className="my-2 *waku-color*/ midashi-color " key={formIndex} >

                  <AreaDropBox form={form} formIndex={formIndex} doChangeDropBox={doChangeDropBox}/>
                  <AreaRadio form={form} formIndex={formIndex} radioPlice={radioPlice} doChangeRadio={doChangeRadio}/>      
                  <AreaCheckBox form={form} formIndex={formIndex} doChangeCheckBox={doChangeCheckBox}/>
                </div>
              ))}
    
              <hr className="hr-color"/>
              <PriceBody price={price}/>
              <Contact infos={infos}/>
              <Infomation infos={infos}/>
            </div>  
            {/* <FooterSec title={"title"} site={"site"}/> */}
          </div>
          <PriceFooter price={price} />
        </div>
      );
    }
    

export default Preview