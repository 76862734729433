import React from 'react'
import Privacy from './Privacy'

const FooterSec = (props) => {
  return (
    <footer className="mt-2 text-muted text-center text-small text-muted pb-2 pt-3 bg-info bg-opacity-25" >
    <a className="" href="/" >
      <img className="my-3" src={`${process.env.PUBLIC_URL}/mitumorImg.png`} width="150" height="auto" alt="" />
    </a>
    <ul className="list-inline list-unstyled mt-3 my">
      <li className="list-inline-item "><a className="text-reset text-muted text-decoration-none" href="" data-bs-toggle="modal" data-bs-target="#policyModal">プライバシーポリシー</a></li>｜
      <li className="list-inline-item "><a className="text-reset text-muted text-decoration-none" href="https://marbou-work.com/contact/">お問合せ</a></li> ｜
      <li className="list-inline-item "><a className="text-reset text-muted text-decoration-none" href="https://marbou-work.com/">運営者情報</a></li>
    </ul>
    {/* <div className="">Created by <a href="https://marbou-work.com/" className="my" target="_blank">{props.site}</a></div> */}
    <div className="mb-1 my">© <a href="https://marbou-work.com/" className="my" target="_blank">{props.site}</a>｜サクッと簡単作成！見積もりシュミレーター！ All rights reserved.</div>
    <Privacy/>
    </footer>
    )
}

export default FooterSec