import React from 'react'

const Infomation = (props) => {
  return (
    <div>
        <div className="m-2 mt-4 text-muted alert alert-light note">{/* お問合せ */}
            <div>
                {props.infos.note
                  ? props.infos.note
                  : ''
                } 
            </div>
        </div>
        <div className="m-2 mt-4 text-muted /*alert alert-light*/ text-center">{/* お問合せ */}
            <div className="fs-6 mb-2">{props.infos.company}</div>
            {/* <div><i className="bi-telephone"></i> {props.infos.tel}</div> */}
            <ul className="for-access">
              {props.infos.mail
                ?<li><a href={`mailto:${props.infos.mail}`} target="_blank"><img src={`${process.env.PUBLIC_URL}/MAIL.png`} className="" alt="メール" /></a></li>
                :''
              }
              {props.infos.site
                ?<li><a href={props.infos.site} target="_blank"><img src={`${process.env.PUBLIC_URL}/WEBSITE3.png`} className="" alt="WEBサイト" /></a></li>
                :''
              }
              {props.infos.x
                ?<li><a href={`https://twitter.com/${props.infos.x}`} target="_blank"><img src={`${process.env.PUBLIC_URL}/X.png`} className="" alt="エックス（X）"   /></a></li>
                :''
              }
              {props.infos.insta
                ?<li><a href={`https://www.instagram.com/${props.infos.insta}`} target="_blank"><img src={`${process.env.PUBLIC_URL}/Insta.png`} className="" alt="インスタ"  /></a></li>
                :''
              }
            </ul>
        </div>
        <hr className="hr-color"/>
    </div>
  )
}

export default Infomation