import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';//tooltip
import Tooltip from 'react-bootstrap/Tooltip';

const Manual = () => {
  return (
    // <div className="alert alert-light mt-3" role="alert" style={{lineHeight: "2"}}>
    <div class="accordion mt-4" id="accordionManual">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseManual" aria-expanded="false" aria-controls="collapseManual">
             お見積りシュミレータの作り方
             <OverlayTrigger overlay={<Tooltip id="tooltip-top">クリックで開閉できます</Tooltip>}>
                <i className="m-2 bi bi-question-circle"></i>
              </OverlayTrigger>
          </button>
        </h2>
        <div id="collapseManual" class="accordion-collapse collapse" data-bs-parent="#accordionManual">
          <div class="accordion-body">
          ① はじめに<span className="text-muted fw-bold">「シュミレータータイトル」</span>入力します。<br/>
        ② 次に <button type="button" className="btn btn-primary btn-sm">+フォーム追加</button> を押しましょう。<br/>
        <span className="biko">
        フォーム入力欄が表示されます。<br/>
        質問に当たるタイトルと、その回答方式である選択肢（ドロップダウン、ラジオボタン、チェックボックス）を選びます。<br/>
        回答となる見積もり項目（項目、＋/ー、金額税率、）が入力できるので入れていきましょう。<br/>
        ※選択肢がドロップダウンとラジオボタンの場合、実際のシュミレータでは先頭の見積もり項目がデフォルトで選択されます。<br/>
        ※また、チェックボックスは複数の選択が可能です。<br/>
        <button type="button" className="btn btn-primary btn-sm">+項目追加</button> を押すことで複数の見積もり項目を作ることができます。<br/>
        フォームも <button type="button" className="btn btn-primary btn-sm">+フォーム追加</button>  を押すことでいくつでもフォームを作ることができます。</span><br/>
        ③ 画面下部にある<strong>「ご連絡先情報・備考など」</strong>も入力していきましょう。<span className="biko">
        <br/>「ご連絡先情報・備考など」は、ユーザーに連絡をしてもらうために必要なメルアドやTELなどの情報や見積りに関する備考など登録できます。</span><br/>
        ④ 作り終えたら <button type="button" className="btn btn-info btn-sm text-white"><i className="bi bi-eye"></i>プレビュー</button> を押しましょう。<br/>
        <span className="biko">作った見積りシュミレータを確認してみましょう。</span><br/>
        ⑤ さいごに<button type="button" className="btn btn-warning btn-sm text-white"><i className="bi bi-arrow-clockwise"></i>更新</button> を押しましょう。<br/>
        <span className="biko">あなたのオリジナル見積りシュミレータは完成です！</span><br/>
        <div className="m-1"><a style={{textDecoration: "none"}} href="/simulator/20231212094622593FszRg" target="_blank" >▶︎ まずはサンプルを見てみる</a></div>
  
        </div>

        </div>
       </div>
    </div>
  )
}

export default Manual