import React from 'react'

const AreaCheckBox = (props) => {
  return (
    <div>

        {props.form.type === "チェックボックス" 
        ? 
        <div className="my-2">
            <div className="my-3">

                <div className="me-2 midashi-color ">
                    <h2 className="midashi fs-6"><strong>Q{props.formIndex+1}. {props.form.label}</strong></h2>
                </div>

                {props.form.fields.map((field, fieldIndex) => (
                    <label className="label-inline" htmlFor={`${props.form.id}${fieldIndex}field.name`} key={fieldIndex}>

                        { typeof field.file === 'object'
                        ? <img className="field-image-preview-checkbox m-2" id={`image_${props.form.id}${fieldIndex}`} src={`${URL.createObjectURL(field.file)}`} />
                        : field.file
                        ? <img className="field-image-preview-checkbox m-2" id={`image_${props.form.id}${fieldIndex}`} src={`${field.file}`} />
                        : ''
                        }   

                        <div className="form-check form-check-inline">
                        <input 
                        onChange={(e)=>props.doChangeCheckBox(e, props.form.id, fieldIndex, props.form.fields)} 
                        className="form-check-input" 
                        type="checkbox" 
                        name={`${props.form.id}${fieldIndex}CheckBox`}
                        id={`${props.form.id}${fieldIndex}field.name`}
                        value={(field.operation+field.amount) * field.tax}
                        //checked={Object.keys(checkBoxePlice).includes(form.id+fieldIndex)}//要素が含まれているかどうか
                        />{field.name}
                    </div>

                    </label>
                ))}
            </div>
        </div>
        :'' 
        }  

    </div>
  )
}

export default AreaCheckBox