import React from 'react'

const AreaRadio = (props) => {
  return (
    <div>

        {props.form.type === "ラジオボタン" 
        ? 
        <div className="my-3 row">                      
            <div className="">
                <div className="me-2 midashi-color ">
                    <h2 className="midashi fs-6"><strong>Q{props.formIndex+1}. {props.form.label}</strong></h2>
                </div>

                {props.form.fields.map((field, fieldIndex) => (
                <label className="label-inline" htmlFor={`${props.form.id}${fieldIndex}Radio`} key={fieldIndex}>

                { typeof field.file === 'object'
                    ? <img className="field-image-preview-checkbox m-2" id={`image_${props.form.id}${fieldIndex}`} src={`${URL.createObjectURL(field.file)}`} />
                    : field.file
                        ? <img className="field-image-preview-checkbox m-2" id={`image_${props.form.id}${fieldIndex}`} src={`${field.file}`} />
                        : ''
                }   

                <div key={fieldIndex} className="form-check form-check-inline">
                {/* {parseFloat(radioPlice[`form${formIndex+1}`])}/{parseFloat(field.operation+field.amount)} */}
                    <input 
                    onChange={(e)=>props.doChangeRadio(e, props.form.id, props.form.fields, fieldIndex)} 
                    className="form-check-input" 
                    type="radio" 
                    id={`${props.form.id}${fieldIndex}Radio`}
                    name={`${props.form.id}${fieldIndex}Radio`}
                    value={(field.operation+field.amount) * field.tax} 
                    checked={parseFloat(props.radioPlice[`form${props.formIndex+1}`]) === parseFloat((field.operation+field.amount) * field.tax)}
                    />{field.name}
                </div>
                </label>
                ))}
            </div>
        </div>
        :'' 
        }            


    </div>
  )
}

export default AreaRadio